const CONFIG = [
  {
    displayName: 'gpt-3.5-turbo',
    version: 'gpt-3.5-turbo',
    maxPromptLength: 8000,
    selectable: true,
  },
  {
    displayName: 'gpt-4',
    version: 'gpt-4',
    maxPromptLength: 17000,
    selectable: false,
  },
  {
    displayName: 'gpt-4-turbo',
    version: 'gpt-4-turbo',
    isDefault: true,
    maxPromptLength: 17000,
    selectable: true,
  },
  {
    displayName: 'gpt-4o',
    version: 'gpt-4o',
    maxPromptLength: 300000,
    selectable: true,
  },
  {
    displayName: 'gpt-4o-mini',
    version: 'gpt-4o-mini',
    maxPromptLength: 300000,
    selectable: true,
  },
  {
    displayName: 'gemini-pro',
    version: 'gemini-pro',
    maxPromptLength: 17000,
    selectable: true,
  },
  {
    displayName: 'gemini-1.5-pro',
    version: 'gemini-1.5-pro-latest',
    maxPromptLength: 17000,
    selectable: true,
  },
];

const _getDefault = () => {
  return CONFIG.find((c) => !!c.isDefault);
};

const getDefaultVersion = () => {
  const versionConfig = _getDefault();
  return versionConfig?.version ?? null;
};

const getModelVersions = () => {
  return CONFIG.map((c) => c.key);
};

const getSelectOptions = () => {
  const list = CONFIG.filter((c) => c.selectable);
  return list.map((c) => ({ key: c.version, value: c.displayName }));
};

const getDisplayName = (version) => {
  const versionConfig = CONFIG.find((c) => c.version === version);
  return versionConfig?.version ?? _getDefault().version;
};

const getMaxPromptLength = (version) => {
  const versionConfig = CONFIG.find((c) => c.version === version);
  return versionConfig?.maxPromptLength ?? _getDefault().maxPromptLength;
};

module.exports = {
  chatGPTModelVersions: {
    getDefaultVersion,
    getModelVersions,
    getSelectOptions,
    getDisplayName,
    getMaxPromptLength,
  },
};
